
import { createStore } from 'vuex'

import app from './modules/app'
import discount from './modules/discount'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {
        app,
        discount,
    },
    strict: debug,
})

export default store